import { useEffect, useRef } from "react";

function useMountedEffect(func: Function, deps: any[]) {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) func();
    else isMounted.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useMountedEffect;
