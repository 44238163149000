import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useAppDispatch } from "../../../app/hooks";
import LengthErrorMsg from "../validation_msg/LengthErrorMsg";
import PattarnErrorMsg from "../validation_msg/PattarnErrorMsg";
import RequiredErrorMsg from "../validation_msg/RequiredErrorMsg";

function MyInputSelect({
  methods,
  name,
  label = "input",
  validators,
  disabled,
  value,
  setValue,
  onChange,
  onBlur,
  onOpen,
  data,
}: any) {
  const dispatch = useAppDispatch();
  return (
    <>
      <FormControl fullWidth>
        <InputLabel
          id={name + "Label"}
          error={methods.formState.errors[name] ? true : false}
        >
          {label}
        </InputLabel>
        <Select
          ref={(e) => methods.register(name, validators).ref(e)}
          name={name}
          labelId={name + "Label"}
          label={label}
          disabled={disabled}
          fullWidth
          value={value ?? ""}
          error={methods.formState.errors[name] ? true : false}
          onChange={(e) => {
            methods.register(name, validators).onChange(e);
            setValue && dispatch(setValue(e.target.value));
            onChange && onChange(e);
          }}
          onBlur={(e) => {
            methods.register(name, validators).onBlur(e);
            onBlur && onBlur(e);
          }}
          onOpen={(e) => {
            onOpen && onOpen(e);
          }}
        >
          <MenuItem key={""} value={""}>
            None
          </MenuItem>
          {data?.map((item: any) => (
            // <MenuItem
            //   key={Object.values(item)[0] as any}
            //   value={Object.values(item)[0] as any}
            // >
            //   {Object.values(item)[1] as any}
            // </MenuItem>
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {methods.formState.errors[name]?.type === "required" && (
        <RequiredErrorMsg name={label} />
      )}
      {methods.formState.errors[name]?.type === "pattern" && (
        <PattarnErrorMsg name={label} />
      )}
      {(methods.formState.errors[name]?.type === "minLength" ||
        methods.formState.errors[name]?.type === "maxLength") && (
        <LengthErrorMsg
          name={label}
          min={validators.minLength}
          max={validators.maxLength}
        />
      )}
    </>
  );
}

export default MyInputSelect;
