import {
  BorderAll,
  Laptop,
  EmojiHeartEyesFill,
  HandbagFill,
} from "styled-icons/bootstrap";
import { Car, Washer } from "styled-icons/boxicons-solid";
import { Chair, SportsFootball } from "styled-icons/material";
import { TabletMobileCombo } from "styled-icons/entypo";
import { ExtensionPuzzle } from "styled-icons/ionicons-outline";
import { GameController } from "styled-icons/ionicons-solid";
import { HotelBed } from "styled-icons/remix-fill";
import { FoodToast } from "styled-icons/fluentui-system-filled";
import { TShirt } from "@styled-icons/boxicons-solid";
import { Books } from "styled-icons/icomoon";
import { Category } from "styled-icons/material-rounded";

const category_icon_width = 24;

export const NAV_CATEGORIES = [
  {
    path: "/products",
    name: "All products",
    icon: <BorderAll width={category_icon_width} />,
  },
  {
    path: "/computing",
    name: "Computing",
    icon: <Laptop width={category_icon_width} />,
  },
  {
    path: "/electronics",
    name: "Electronics",
    icon: <Washer width={category_icon_width} />,
  },
  {
    path: "/sporting-goods",
    name: "Sporting Goods",
    icon: <SportsFootball width={category_icon_width} />,
  },
  {
    path: "/phones-tablets",
    name: "Phones & Tablets",
    icon: <TabletMobileCombo width={category_icon_width} />,
  },
  {
    path: "/toys-games",
    name: "Toys & Games",
    icon: <ExtensionPuzzle width={category_icon_width} />,
  },
  {
    path: "/gaming",
    name: "Gaming",
    icon: <GameController width={category_icon_width} />,
  },
  {
    path: "/home-office",
    name: "Home & Office",
    icon: <Chair width={category_icon_width} />,
  },
  {
    path: "/supermarket",
    name: "Supermarket",
    icon: <FoodToast width={category_icon_width} />,
  },
  {
    path: "/health-beauty",
    name: "Health & Beauty",
    icon: <HandbagFill width={category_icon_width} />,
  },
  {
    path: "/fashion",
    name: "Fashion",
    icon: <TShirt width={category_icon_width} />,
  },
  {
    path: "/automobile",
    name: "Automobile",
    icon: <Car width={category_icon_width} />,
  },
  {
    path: "/books-novels",
    name: "Books & Novels",
    icon: <Books width={category_icon_width} />,
  },
  {
    path: "/other-categories",
    name: "Other categories",
    icon: <Category width={category_icon_width} />,
  },
];
