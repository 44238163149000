import { Button, CircularProgress, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Celebration } from "styled-icons/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { setCheckoutStep } from "../../../../features/checkout.feature";

function Confirmation() {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state: RootState) => state.loader);
  const { order } = useAppSelector((state: RootState) => state.checkout);

  useEffect(() => {
    return () => {
      dispatch(setCheckoutStep(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "300px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {!isLoading ? (
        <>
          <Typography variant="h4" color="green">
            Success!
          </Typography>
          <div>
            <Typography variant="h6">
              Thank you for your purchase, {order.customer.firstname}{" "}
              {order.customer.lastname}
            </Typography>
            <br />
            <Divider />
            <br />
            <Typography variant="subtitle2">
              Order ref: {order.customer_reference}
            </Typography>
          </div>
          <Button
            component={Link}
            to="/"
            variant="outlined"
            size="large"
            type="button"
          >
            Back to Home
          </Button>
        </>
      ) : (
        <>
          <div />
          <CircularProgress />
          <div />
        </>
      )}
    </div>
  );
}

export default Confirmation;
