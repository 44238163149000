import { Button, Link } from "@mui/material";
import styles from "./Footer.module.scss";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.container + " container"}>
        <span>2022 © Copyright. All Rights Reserved</span>
        <span>
          Designed by{" "}
          <Button
            component={Link}
            href="https://ahmed-ouda.vercel.app"
            target="_blank"
            rel="noreferrer"
          >
            Ahmed Ouda
          </Button>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
