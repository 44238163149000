import { GetShippingOptionsResponse } from "@chec/commerce.js/features/checkout";
import {
  LocaleListCountriesResponse,
  LocaleListSubdivisionsResponse,
} from "@chec/commerce.js/features/services";
import { CheckoutCapture } from "@chec/commerce.js/types/checkout-capture";
import { CheckoutCaptureResponse } from "@chec/commerce.js/types/checkout-capture-response";
import { CheckoutToken } from "@chec/commerce.js/types/checkout-token";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Stripe, StripeCardElement } from "@stripe/stripe-js";
import { commerce } from "../common/lib/commerce";
import { AddressModel } from "../common/models/Forms";
import { refreshCart } from "./cart.feature";

const initialState = {
  status: "idle",
  error: null as any,
  step: 0,
  checkoutToken: {} as any,
  shippingCountryList: [] as any[],
  shippingSubdivisionList: [] as any[],
  shippingOptionList: [] as any[],
  addressFormObj: {} as AddressModel,
  order: {} as CheckoutCaptureResponse,
};

export const generateCheckoutTkn = createAsyncThunk(
  "checkout/generateCheckoutTkn",
  async (cartId: string) => {
    try {
      return await commerce.checkout.generateToken(cartId, { type: "cart" });
    } catch (error) {
      throw await error;
    }
  }
);

export const fetchShipCountries = createAsyncThunk(
  "checkout/fetchShipCountries",
  async (checkoutTokenId: string) => {
    try {
      return await commerce.services.localeListShippingCountries(
        checkoutTokenId
      );
    } catch (error) {
      throw await error;
    }
  }
);

export const fetchSubdivisions = createAsyncThunk(
  "checkout/fetchSubdivisions",
  async (countryCode: string) => {
    try {
      return await commerce.services.localeListSubdivisions(countryCode);
    } catch (error) {
      throw await error;
    }
  }
);

type FetchShipOptionsObj = {
  checkoutTokenId: string;
  country: string;
  stateProvince?: string;
};

export const fetchShipOptions = createAsyncThunk(
  "checkout/fetchShipOptions",
  async ({ checkoutTokenId, country, stateProvince }: FetchShipOptionsObj) => {
    try {
      return await commerce.checkout.getShippingOptions(checkoutTokenId, {
        country,
        region: stateProvince,
      });
    } catch (error) {
      throw await error;
    }
  }
);

type SubmitPaymentFormObj = {
  stripe: Stripe;
  card: StripeCardElement;
  checkoutToken: CheckoutToken;
  addressFormObj: AddressModel;
};

export const submitPaymentForm = createAsyncThunk(
  "checkout/submitPaymentForm",
  async (
    { stripe, card, checkoutToken, addressFormObj }: SubmitPaymentFormObj,
    { dispatch }
  ) => {
    try {
      const res = await stripe.createPaymentMethod({
        type: "card",
        card: card,
      });

      if (!res.error) {
        const orderData: CheckoutCapture = {
          line_items: checkoutToken.line_items,
          customer: {
            firstname: addressFormObj.firstName,
            lastname: addressFormObj.lastName,
            email: addressFormObj.email,
          },
          shipping: {
            name: "primary",
            street: addressFormObj.address1,
            town_city: addressFormObj.city,
            county_state: addressFormObj.shippingSubdivision,
            postal_zip_code: addressFormObj.zip,
            country: addressFormObj.shippingCountry,
          },
          fulfillment: { shipping_method: addressFormObj.shippingOption },
          payment: {
            gateway: "test_gateway",
            card: {
              number: "4242 4242 4242 4242",
              expiry_month: "01",
              expiry_year: "2023",
              cvc: "123",
              postal_zip_code: "94103",
            },
          },
        };

        dispatch(refreshCart());
        dispatch(setCheckoutStep(2));
        dispatch(
          setOrder(await commerce.checkout.capture(checkoutToken.id, orderData))
        );
      }
    } catch (error) {
      throw await error;
    }
  }
);

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: initialState,
  reducers: {
    // ============= Checkout General Methods =============
    setCheckoutStep: (state, action) => {
      if (action.payload > -1 && action.payload < 3)
        state.step = action.payload;
    },
    // ============= Address Form Methods ===============
    clearSubdivitionList: (state) => {
      state.shippingSubdivisionList = [];
    },
    clearShipOptionList: (state) => {
      state.shippingOptionList = [];
    },
    setShippingCountry: (state, action) => {
      state.addressFormObj.shippingCountry = action.payload;
    },
    setShippingSubdivision: (state, action) => {
      state.addressFormObj.shippingSubdivision = action.payload;
    },
    setShippingOption: (state, action) => {
      state.addressFormObj.shippingOption = action.payload;
    },
    setAddressForm: (state, action) => {
      state.addressFormObj = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    // setOrderError: (state, action) => {
    //   state.status = "failed";
    //   state.error = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      // =========== Generate Checkout Token From Current Cart ================
      .addCase(generateCheckoutTkn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(generateCheckoutTkn.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.checkoutToken = action.payload as CheckoutToken;
      })
      .addCase(generateCheckoutTkn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // =========== Fetch Shipping Country List ================
      .addCase(fetchShipCountries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShipCountries.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        const res = action.payload as LocaleListCountriesResponse;
        state.shippingCountryList = Object.entries(res.countries).map(
          ([id, name]) => ({ id, name })
        );
      })
      .addCase(fetchShipCountries.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // =========== Fetch Subdivision List ================
      .addCase(fetchSubdivisions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubdivisions.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        const res = action.payload as LocaleListSubdivisionsResponse;
        state.shippingSubdivisionList = Object.entries(res.subdivisions).map(
          ([id, name]) => ({ id, name })
        );
      })
      .addCase(fetchSubdivisions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // =========== Fetch Shipping Option List ================
      .addCase(fetchShipOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShipOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        const res = action.payload as GetShippingOptionsResponse[];
        state.shippingOptionList = res.map((item) => {
          return {
            id: item.id,
            name: `${item.description} = (${item.price.formatted_with_code})`,
          };
        });
      })
      .addCase(fetchShipOptions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // =========== Submit Payment Form ===========
      .addCase(submitPaymentForm.pending, (state) => {
        state.status = "loading";
      })
      .addCase(submitPaymentForm.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(submitPaymentForm.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  setCheckoutStep,
  clearSubdivitionList,
  clearShipOptionList,
  setShippingCountry,
  setShippingOption,
  setShippingSubdivision,
  setAddressForm,
  setOrder,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
