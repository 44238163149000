import styles from "./Products.module.scss";
import ProductCard from "../../common/components/products/product_card/ProductCard";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  emptyProducts,
  fetchProducts,
  setCat,
  setSearch,
} from "../../features/products.feature";
import { Button, CircularProgress, Pagination } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

function Products({ category }: any) {
  const location = useLocation();
  const isLoading = useAppSelector(
    (state: RootState) => state.loader.isLoading
  );
  const { productCollection, search, cat, error } = useAppSelector(
    (state: RootState) => state.products
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getProducts = async () => {
      const searchParam = await location.search
        .slice(1)
        .split("&")
        .find((ele) => ele.startsWith("s="))
        ?.split("=")[1];

      let productParams = {} as any;
      searchParam && (productParams.query = await decodeURI(searchParam));

      category !== "/products" &&
        (productParams.category_slug = category.slice(1));

      if (cat !== category || search !== productParams.query) {
        dispatch(emptyProducts());

        await dispatch(fetchProducts(productParams));
      }

      search !== productParams.query &&
        dispatch(setSearch(productParams.query));

      cat !== category && dispatch(setCat(category));
    };

    getProducts();

    // return () => {
    //   dispatch(emptyProducts());
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, category]);

  function handlePagenation(e: any) {
    const page = parseInt(e.target.textContent);
    const param = { page: page } as any;
    search && (param.query = search);
    productCollection?.meta.pagination.current_page !== page &&
      dispatch(fetchProducts(param));
  }

  return (
    <>
      <main className={styles.root}>
        <div className="container">
          {productCollection ? (
            productCollection.data ? (
              <div className={styles.products}>
                {productCollection.meta.pagination.total_pages > 1 && (
                  <Pagination
                    page={productCollection.meta.pagination.current_page}
                    count={productCollection.meta.pagination.total_pages}
                    disabled={isLoading}
                    hideNextButton
                    hidePrevButton
                    onClick={handlePagenation}
                  />
                )}
                <div className={styles.grid__container}>
                  <div className={styles.grid}>
                    {productCollection.data.length > 0 &&
                      productCollection.data.map(
                        (product, index) =>
                          product.active && (
                            <ProductCard
                              key={index}
                              product={product}
                              category={category}
                            />
                          )
                      )}
                  </div>
                </div>
                {productCollection.meta.pagination.total_pages > 1 && (
                  <Pagination
                    page={productCollection.meta.pagination.current_page}
                    count={productCollection.meta.pagination.total_pages}
                    disabled={isLoading}
                    hideNextButton
                    hidePrevButton
                    onClick={handlePagenation}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  position: "fixed",
                  top: "45%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  {error}
                </p>
                <Button
                  component={Link}
                  to={"/"}
                  color="error"
                  disabled={isLoading}
                >
                  Go To Home
                </Button>
              </div>
            )
          ) : (
            <CircularProgress
              style={{
                position: "fixed",
                top: "calc(45% - 40px)",
                left: "calc(50% - 40px)",
                width: "80px",
                height: "80px",
              }}
            />
          )}
        </div>
      </main>
      {/* <ProductDetails /> */}
    </>
  );
}

export default Products;
