import { Button, Typography } from "@mui/material";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { FormEvent } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { refreshCart } from "../../../features/cart.feature";
import {
  setCheckoutStep,
  submitPaymentForm,
} from "../../../features/checkout.feature";

function StripeForm() {
  const stripe = useStripe();
  const elements = useElements();

  const isLoading = useAppSelector(
    (state: RootState) => state.loader.isLoading
  );
  const { step, checkoutToken, addressFormObj } = useAppSelector(
    (state: RootState) => state.checkout
  );

  const dispatch = useAppDispatch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const card = elements?.getElement(CardElement);

    if (
      elements === null ||
      stripe === null ||
      card === null ||
      card === undefined
    ) {
      return;
    }

    dispatch(
      submitPaymentForm({ stripe, card, checkoutToken, addressFormObj })
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <br /> <br />
      <Typography variant="body1" color="GrayText">
        Testing visa:
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto auto auto",
          columnGap: "12px",
          paddingInlineEnd: "8px",
        }}
      >
        <Typography variant="body2" color="GrayText">
          4242 4242 4242 4242
        </Typography>
        <Typography variant="body2" color="GrayText">
          04 / 24
        </Typography>
        <Typography variant="body2" color="GrayText">
          242
        </Typography>
        <Typography variant="body2" color="GrayText">
          42424
        </Typography>
      </div>
      <br /> <br />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          size="large"
          disabled={isLoading}
          onClick={() => {
            dispatch(setCheckoutStep(step - 1));
          }}
        >
          Back
        </Button>
        <Button
          type="submit"
          disabled={!stripe || !elements || isLoading}
          variant="contained"
          color="primary"
          size="large"
        >
          Pay {checkoutToken.subtotal.formatted_with_symbol}
        </Button>
      </div>
    </form>
  );
}

export default StripeForm;
