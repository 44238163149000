import { Button, CircularProgress, IconButton } from "@mui/material";
import { useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  emptyProd,
  fetchProd,
  setProdQuantity,
  setProductId,
} from "../../../features/products.feature";
import no_img from "../../../assets/images/no_img_2.png";
import styles from "./ProductDetails.module.scss";
import { addToCart } from "../../../features/cart.feature";
import { AddShoppingCart } from "styled-icons/material";
import ProductCard from "../../../common/components/products/product_card/ProductCard";
import { Minus, Plus } from "styled-icons/entypo";

function ProductDetails({ category }: { category: string }) {
  const isLoading = useAppSelector(
    (state: RootState) => state.loader.isLoading
  );
  const { productId } = useParams();
  const { prodId, product, prodQuantity } = useAppSelector(
    (state: RootState) => state.products
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getProd = async () => {
      if (productId && productId !== prodId) {
        dispatch(emptyProd());
        await dispatch(fetchProd(productId));
        dispatch(setProductId(productId));
      }
    };

    getProd();

    return () => {
      dispatch(setProdQuantity(1));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return (
    <div className={styles.root}>
      <div className="container">
        {product ? (
          <>
            <div className={styles.product}>
              <div className={styles.product__img__container}>
                {product.assets?.length > 1 ? (
                  <Carousel>
                    {product.assets.map((img) => (
                      <div className={styles.product__img} key={img.id}>
                        <img src={img.url} alt={product.name} />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div className={styles.product__img}>
                    <img
                      src={product.assets[0]?.url ?? no_img}
                      alt={product.name}
                    />
                  </div>
                )}
              </div>
              <div className={styles.product__details}>
                <h1 className={styles.product__title}>{product.name}</h1>
                <p className={styles.product__price}>
                  {product.price.formatted_with_symbol}
                </p>
                <div
                  className={styles.product__description}
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
                {!product.is.sold_out ? (
                  <>
                    <div className={styles.product__quantity}>
                      <div className={styles.quantity}>
                        <IconButton
                          className={styles.minus__q}
                          type="button"
                          color="error"
                          size="large"
                          disabled={prodQuantity <= 1 || isLoading}
                          onClick={() => {
                            dispatch(setProdQuantity(prodQuantity - 1));
                          }}
                        >
                          <Minus />
                        </IconButton>
                        <div className={styles.value__q}>
                          <input
                            type="number"
                            value={prodQuantity}
                            min={1}
                            max={99}
                            disabled={isLoading}
                            onChange={(e) => {
                              const val = parseInt(e.target.value);
                              dispatch(
                                setProdQuantity(
                                  val > 0 ? (val > 99 ? 99 : val) : 1
                                )
                              );
                            }}
                          />
                        </div>
                        <IconButton
                          className={styles.plus__q}
                          type="button"
                          color="primary"
                          size="large"
                          disabled={isLoading}
                          onClick={() => {
                            dispatch(setProdQuantity(prodQuantity + 1));
                          }}
                        >
                          <Plus />
                        </IconButton>
                      </div>
                      <Button
                        className={styles.product__actions}
                        disabled={isLoading}
                        variant="contained"
                        onClick={() => {
                          dispatch(
                            addToCart({
                              productId: product.id,
                              quantity: prodQuantity,
                            })
                          );
                        }}
                      >
                        <AddShoppingCart
                          width={24}
                          style={{ marginInlineEnd: "4px" }}
                        />
                        Add to cart
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button className={styles.product__actions} disabled={true}>
                    <AddShoppingCart
                      width={24}
                      style={{ marginInlineEnd: "4px" }}
                    />
                    OUT OF STOCK
                  </Button>
                )}
              </div>
            </div>
            {product.related_products && product.related_products.length > 0 && (
              <div className={styles.product__recommended}>
                <h2 className={styles.recommended__title}>You may also like</h2>
                <div className={styles.list__recommended}>
                  <div className={styles.recommended}>
                    {product.related_products.map((item) => (
                      <ProductCard
                        className={styles.recommended__card}
                        product={item}
                        category={category}
                        key={item.id}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <CircularProgress
            style={{
              position: "fixed",
              top: "calc(45% - 40px)",
              left: "calc(50% - 40px)",
              width: "80px",
              height: "80px",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ProductDetails;
