function LengthErrorMsg({ name, min, max }: any) {
  if (min && max)
    return (
      <small className="error__text">
        {name ?? "Field"} length must be between {min} ~ {max} characters
      </small>
    );
  if (min)
    return (
      <small className="error__text">
        {name ?? "Field"} length must be at least {min} characters
      </small>
    );
  if (max)
    return (
      <small className="error__text">
        {name ?? "Field"} length must not exceed {max} characters
      </small>
    );
  return <small className="error__text">{name ?? "Field"} length error</small>;
}

export default LengthErrorMsg;
