import { Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import CartItem from "../../common/components/cart/cart_item/CartItem";
import { emptyCart } from "../../features/cart.feature";
import { setSearch } from "../../features/products.feature";
import styles from "./Cart.module.scss";

function Cart() {
  const isLoading = useAppSelector(
    (state: RootState) => state.loader.isLoading
  );
  const { cart } = useAppSelector((state: RootState) => state.cart);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSearch(undefined));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={styles.container + " container"}>
        <Typography className={styles.title} variant="h4">
          Your Shopping Cart
        </Typography>
        {cart.total_items > 0 ? (
          <>
            {/* <Grid container justifyContent="center" spacing={3}> */}
            <div className={styles.products__container}>
              <div className={styles.products}>
                {cart.line_items.map((item: any) => (
                  <CartItem key={item.id} item={item} />
                ))}
              </div>
            </div>
            {/* </Grid> */}
            <div className={styles.cart__details}>
              <Typography variant="h4">
                Subtotal: {cart.subtotal.formatted_with_symbol}
              </Typography>
              <div className={styles.cart__details__btns}>
                <Button
                  className={styles.empty__btn}
                  size="large"
                  type="button"
                  variant="contained"
                  color="error"
                  disabled={isLoading}
                  onClick={() => {
                    dispatch(emptyCart());
                  }}
                >
                  Empty Cart
                </Button>
                <Button
                  className={styles.checkout__btn}
                  size="large"
                  type="button"
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    if (cart?.line_items?.length > 0) navigate("/checkout");
                  }}
                >
                  Checkout
                </Button>
              </div>
            </div>
          </>
        ) : (
          !isLoading && (
            <Typography variant="subtitle1" className={styles.empty__cart}>
              Your shopping cart is empty,
              <Button component={Link} to={"/"} variant="text" color="error">
                start adding some!
              </Button>
            </Typography>
          )
        )}
      </div>
    </div>
  );
}

export default Cart;
