import styles from "./ShowCard.module.scss";
import no_img from "../../../../assets/images/no_img_2.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { AddShoppingCart } from "styled-icons/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { addToCart } from "../../../../features/cart.feature";
import { Product } from "@chec/commerce.js/types/product";

function ShowCard({ product }: { product: Product }) {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    (state: RootState) => state.loader.isLoading
  );

  return (
    <div className={styles.card}>
      <div className={styles.card__img}>
        <img
          src={product.image?.url ?? (product && no_img)}
          alt={product.name}
        />
      </div>
      <div className={styles.card__details}>
        <h2 className={styles.card__title}>{product.name}</h2>
        <div
          className={styles.card__description}
          dangerouslySetInnerHTML={{ __html: product.description }}
        />
        <p className={styles.card__price}>
          {product.price.formatted_with_symbol}
        </p>
        {!product.is.sold_out ? (
          <Button
            className={styles.card__actions}
            disabled={isLoading}
            onClick={() => {
              dispatch(addToCart({ productId: product.id }));
            }}
          >
            <AddShoppingCart width={24} style={{ marginInlineEnd: "4px" }} />
            Add to cart
          </Button>
        ) : (
          <Button className={styles.card__actions} disabled={true}>
            <AddShoppingCart width={24} style={{ marginInlineEnd: "4px" }} />
            OUT OF STOCK
          </Button>
        )}
      </div>
      <div className={styles.show__details}>
        <Button
          component={Link}
          to={`/products/${product.name.replace(" ", "-")}/${product.id}`}
          variant="contained"
          size="large"
          disabled={isLoading}
        >
          Show details
        </Button>
      </div>
    </div>
  );
}

export default ShowCard;
