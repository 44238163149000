import { Cart } from "@chec/commerce.js/types/cart";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commerce } from "../common/lib/commerce";

const initialState = {
  status: "idle",
  cart: {} as Cart,
};

export const fetchCart = createAsyncThunk("cart/fetchCart", async () => {
  try {
    return await commerce.cart.retrieve();
  } catch (error) {
    throw await error;
  }
});

type AddToCartParams = {
  productId: string;
  quantity?: number;
};

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async ({ productId, quantity = 1 }: AddToCartParams) => {
    try {
      return await commerce.cart.add(productId, quantity);
    } catch (error) {
      throw await error;
    }
  }
);

type UpdateItemCartParams = {
  itemId: string;
  quantity: number;
};

export const updateItemCart = createAsyncThunk(
  "cart/updateItemCart",
  async ({ itemId, quantity }: UpdateItemCartParams) => {
    try {
      if (quantity >= 1)
        return await commerce.cart.update(itemId, { quantity });
      else return await commerce.cart.remove(itemId);
    } catch (error) {
      throw await error;
    }
  }
);

export const removeItemCart = createAsyncThunk(
  "cart/removeItemCart",
  async (itemId: string) => {
    try {
      return await commerce.cart.remove(itemId);
    } catch (error) {
      throw await error;
    }
  }
);

export const emptyCart = createAsyncThunk("cart/emptyCart", async () => {
  try {
    return await commerce.cart.empty();
  } catch (error) {
    throw await error;
  }
});

export const refreshCart = createAsyncThunk("cart/refreshCart", async () => {
  try {
    return await commerce.cart.refresh();
  } catch (error) {
    throw await error;
  }
});

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {},
  // addToCart: (state, action) => {
  //   state.cart.push(action.payload as Product);
  // },
  // removeItemCart: (state, action) => {
  //   state.cart.filter((item: Product) => item.id !== action.payload);
  // },
  // emptyAllFromCart: (state) => {
  //   state.cart = [];
  // },
  extraReducers: (builder) => {
    builder
      // =========== Fetch Cart ================
      .addCase(fetchCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.status = "idle";
        state.cart = action.payload as Cart;
      })
      .addCase(fetchCart.rejected, (state) => {
        state.status = "failed";
      })
      // =========== Add To Cart ================
      .addCase(addToCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.status = "idle";
        state.cart = action.payload as any;
      })
      .addCase(addToCart.rejected, (state) => {
        state.status = "failed";
      })
      // =========== Update Item Quantity in Cart ================
      .addCase(updateItemCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateItemCart.fulfilled, (state, action) => {
        state.status = "idle";
        state.cart = action.payload as any;
      })
      .addCase(updateItemCart.rejected, (state) => {
        state.status = "failed";
      })
      // =========== Remove Item From Cart ================
      .addCase(removeItemCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeItemCart.fulfilled, (state, action) => {
        state.status = "idle";
        state.cart = action.payload as any;
      })
      .addCase(removeItemCart.rejected, (state) => {
        state.status = "failed";
      })
      // ============ Empty Cart ================
      .addCase(emptyCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(emptyCart.fulfilled, (state, action) => {
        state.status = "idle";
        state.cart = action.payload as any;
      })
      .addCase(emptyCart.rejected, (state) => {
        state.status = "failed";
      })
      // ============ Refresh Cart ================
      .addCase(refreshCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(refreshCart.fulfilled, (state, action) => {
        state.status = "idle";
        state.cart = action.payload as Cart;
      })
      .addCase(refreshCart.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// export const { addToCart, removeItemCart, emptyAllFromCart }   = cartSlice.actions;
export default cartSlice.reducer;
