import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import cartReducer from "../features/cart.feature";
import checkoutReducer from "../features/checkout.feature";
import homeReducer from "../features/home.feature";
import loaderReducer from "../features/loader.feature";
import productsReducer from "../features/products.feature";

export const store = configureStore({
  reducer: {
    home: homeReducer,
    products: productsReducer,
    loader: loaderReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
