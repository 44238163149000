import { useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import CategoryCard from "../../common/components/home/category_card/CategoryCard";
import ShowCard from "../../common/components/home/show_card/ShowCard";
import { setSearch } from "../../features/products.feature";
import styles from "./Home.module.scss";

function Home() {
  const { show, category } = useAppSelector((state: RootState) => state.home);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSearch(undefined));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.home}>
          <Carousel swipe animation="slide" className={styles.carousel}>
            {show?.data?.map((item, i) => (
              <ShowCard key={i} product={item} />
            ))}
          </Carousel>
          {category && (
            <div className={styles.categories}>
              <CategoryCard />
              {category.data.map((cat, i) => {
                return <CategoryCard key={i} category={cat} />;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
