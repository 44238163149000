export type SearchModel = {
  search?: string;
};

export type AddressModel = {
  firstName: string;
  lastName: string;
  address1: string;
  email: string;
  zip: string;
  city: string;
  shippingCountry: string;
  shippingSubdivision: string;
  shippingOption: string;
};

export const AddressInputFieldElements = [
  {
    name: "firstName",
    label: "First name",
    validators: {
      required: true,
      pattern: /^[A-Z\u0621-\u064A]+( [A-Z\u0621-\u064A]+)*$/i,
      minLength: 3,
      maxLength: 30,
    },
  },
  {
    name: "lastName",
    label: "Last name",
    validators: {
      required: true,
      pattern: /^[A-Z\u0621-\u064A]+( [A-Z\u0621-\u064A]+)*$/i,
      minLength: 3,
      maxLength: 50,
    },
  },
  {
    name: "address1",
    label: "Address line 1",
    validators: {
      required: true,
      minLength: 3,
    },
  },
  {
    name: "email",
    label: "Email",
    validators: {
      required: true,
      pattern: /^[A-Z0-9]+([._-][A-Z0-9]+)*@[A-Z]+(\.[A-Z]+)+$/i,
    },
  },
  {
    name: "city",
    label: "City",
    validators: {
      required: true,
      pattern: /^[A-Z\u0621-\u064A]+( [A-Z\u0621-\u064A]+)*$/i,
    },
  },
  {
    type: "number",
    name: "zip",
    label: "Zip / Postal code",
    validators: {
      required: true,
      min: 3,
    },
  },
  // {
  //   type: "select",
  //   name: "shippingCountry",
  //   label: "Shipping Country",
  //   validators: {
  //     required: true,
  //   },
  // },
  // {
  //   type: "select",
  //   name: "shippingSubdivision",
  //   label: "Shipping Subdivision",
  //   validators: {
  //     required: true,
  //   },
  // },
  // {
  //   type: "select",
  //   name: "shippingOptions",
  //   label: "Shipping Options",
  //   validators: {
  //     required: true,
  //   },
  // },
];
