import { ProductCollection } from "@chec/commerce.js/features/products";
import { Product } from "@chec/commerce.js/types/product";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commerce } from "../common/lib/commerce";

const initialState = {
  productCollection: undefined as ProductCollection | undefined,
  status: "idle",
  error: null as any,
  search: undefined as string | undefined,
  cat: "",
  prodId: undefined as string | undefined,
  product: undefined as Product | undefined,
  prodQuantity: 1,
};

type ListProductsParams = {
  category_slug?: string;
  category_id?: string;
  query?: string;
  limit?: number;
  active?: boolean;
  price_above?: number;
  price_below?: number;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({
    page,
    query,
    category_id,
    category_slug,
    limit,
    sortBy,
    sortDirection,
  }: ListProductsParams) => {
    try {
      return await commerce.products.list({
        active: 1,
        page,
        query,
        category_id,
        category_slug,
        limit,
        sortBy,
        sortDirection,
      });
    } catch (error) {
      throw await error;
    }
  }
);

export const fetchProd = createAsyncThunk(
  "fetchProd",
  async (productId: string) => {
    try {
      return await commerce.products.retrieve(productId);
    } catch (error) {
      throw await error;
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setCat: (state, action) => {
      state.cat = action.payload;
    },
    setProductId: (state, action) => {
      state.prodId = action.payload;
    },
    setProdQuantity: (state, action) => {
      state.prodQuantity = action.payload;
    },
    emptyProducts: (state) => {
      state.productCollection = undefined;
    },
    emptyProd: (state) => {
      state.product = undefined;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        const res = action.payload as ProductCollection;
        if (res.data) {
          state.status = "idle";
          state.error = null;
        } else {
          state.status = "idle";
          state.error = "This category is empty for now.";
        }
        state.productCollection = res;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.status = "failed";
        state.error = "This category is empty for now.";
      })
      .addCase(fetchProd.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProd.fulfilled, (state, action) => {
        state.status = "idle";
        state.product = action.payload as Product;
      })
      .addCase(fetchProd.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  emptyProducts,
  emptyProd,
  setSearch,
  setCat,
  setProductId,
  setProdQuantity,
} = productsSlice.actions;
export default productsSlice.reducer;
