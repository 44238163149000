import {
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import AddressForm from "../../common/components/checkout/address_form/AddressForm";
import Confirmation from "../../common/components/checkout/confirmation/Confirmation";
import PaymentForm from "../../common/components/checkout/payment_form/PaymentForm";
import styles from "./Checkout.module.scss";

const steps = ["Shipping", "Payment"];

function Checkout() {
  const { step } = useAppSelector((state: RootState) => state.checkout);

  return (
    <Container className={styles.container}>
      <Paper className={styles.paper}>
        <Typography variant="h4" align="center">
          Checkout
        </Typography>
        <Container>
          <Stepper activeStep={step} className={styles.stepper}>
            {steps.map((item) => (
              <Step key={item}>
                <StepLabel>{item}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {step === 0 && <AddressForm />}
          {step === 1 && <PaymentForm />}
          {step === 2 && <Confirmation />}
        </Container>
      </Paper>
    </Container>
  );
}

export default Checkout;
