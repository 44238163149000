import { TextField } from "@mui/material";
import RequiredErrorMsg from "../validation_msg/RequiredErrorMsg";
import PattarnErrorMsg from "../validation_msg/PattarnErrorMsg";
import LengthErrorMsg from "../validation_msg/LengthErrorMsg";

function MyInputField({
  methods,
  name,
  label = "input",
  type = "text",
  disabled,
  validators,
  onChange,
  onBlur,
}: any) {
  return (
    <>
      <TextField
        ref={(e) => methods.register(name, validators).ref(e)}
        name={name}
        label={label}
        type={type}
        disabled={disabled}
        fullWidth
        error={methods.formState.errors[name] ? true : false}
        onChange={(e) => {
          methods.register(name, validators).onChange(e);
          onChange && onChange(e);
        }}
        onBlur={(e) => {
          methods.register(name, validators).onBlur(e);
          onBlur && onBlur(e);
        }}
      />
      {methods.formState.errors[name]?.type === "required" && (
        <RequiredErrorMsg name={label} />
      )}
      {methods.formState.errors[name]?.type === "pattern" && (
        <PattarnErrorMsg name={label} />
      )}
      {(methods.formState.errors[name]?.type === "minLength" ||
        methods.formState.errors[name]?.type === "maxLength") && (
        <LengthErrorMsg
          name={label}
          min={validators.minLength}
          max={validators.maxLength}
        />
      )}
    </>
  );
}

export default MyInputField;
