import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { setCheckoutStep } from "../../../../features/checkout.feature";
import environment from "../../../environment/environment";
import StripeForm from "../StripeForm";

const stripePromise = loadStripe(environment.stripe_public_key);

function PaymentForm() {
  const { checkoutToken } = useAppSelector(
    (state: RootState) => state.checkout
  );
  const navigate = useNavigate();
  const { cart } = useAppSelector((state: RootState) => state.cart);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (typeof cart.id === "undefined" || cart.line_items.length === 0) {
      dispatch(setCheckoutStep(0));
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {checkoutToken.line_items.map((product: any) => (
          <ListItem style={{ padding: "10px 0" }} key={product.name}>
            <ListItemText
              primary={product.name}
              secondary={`Quantity: ${product.quantity}`}
            />
            <Typography variant="body2">
              {product.line_total.formatted_with_symbol}
            </Typography>
          </ListItem>
        ))}
        <ListItem style={{ padding: "10px 0" }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
            {checkoutToken.subtotal.formatted_with_symbol}
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <Typography variant="h6" gutterBottom style={{ margin: "20px 0" }}>
        Payment method
      </Typography>
      <Elements stripe={stripePromise}>
        <StripeForm />
      </Elements>
      {/* {step > 0 && (
        <Button
          onClick={() => {
            dispatch(setCheckoutStep(step - 1));
          }}
        >
          prev
        </Button>
      )} */}
    </>
  );
}

export default PaymentForm;
