import { Link } from "react-router-dom";
import styles from "./CategoryCard.module.scss";
import all_cat from "../../../../assets/images/all_cat_1.png";

function CategoryCard({ category, style }: any) {
  return (
    <Link
      to={`/${category?.slug ?? "products"}`}
      className={styles.card + " anchor__colored"}
      style={style}
    >
      <div className={styles.card__image}>
        <img
          src={category?.assets[0].url ?? all_cat}
          alt={category?.name ?? "All products"}
        />
      </div>
      <div className={styles.card__title}>
        <h3>{category?.name ?? "All products"}</h3>
      </div>
    </Link>
  );
}

export default CategoryCard;
