import { CardContent, Typography, IconButton, Button } from "@mui/material";
import { CheveronDown, CheveronUp } from "styled-icons/zondicons";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import {
  updateItemCart,
  removeItemCart,
} from "../../../../features/cart.feature";
import no_img from "../../../../assets/images/no_img_2.png";
import styles from "./CartItem.module.scss";

function CartItem({ item }: any) {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    (state: RootState) => state.loader.isLoading
  );

  return (
    // <Grid item xs={12} sm={6} md={4} lg={3}>
    <div className={styles.card}>
      <div className={styles.card__img}>
        <img src={item.image?.url ?? (item && no_img)} alt={item.name} />
      </div>
      <CardContent className={styles.card__content}>
        <Typography variant="h6" className={styles.card__title}>
          {item.name}
        </Typography>
        <Typography variant="h6" className={styles.card__price}>
          {item.price.formatted_with_symbol}
        </Typography>
        <div className={styles.remove__btn}>
          <Button
            type="button"
            color="error"
            disabled={isLoading}
            variant="outlined"
            onClick={() => {
              dispatch(removeItemCart(item.id));
            }}
          >
            Remove
          </Button>
        </div>
        <div className={styles.card__quantity}>
          <div className={styles.quantity}>
            <IconButton
              type="button"
              color="primary"
              disabled={isLoading}
              onClick={() => {
                dispatch(
                  updateItemCart({
                    itemId: item.id,
                    quantity: item.quantity + 1,
                  })
                );
              }}
            >
              <CheveronUp width={24} />
            </IconButton>
            <Typography variant="body2">{item.quantity}</Typography>
            <IconButton
              type="button"
              color="error"
              disabled={item.quantity <= 1 || isLoading}
              onClick={() => {
                dispatch(
                  updateItemCart({
                    itemId: item.id,
                    quantity: item.quantity - 1,
                  })
                );
              }}
            >
              <CheveronDown width={24} />
            </IconButton>
          </div>
        </div>
      </CardContent>
    </div>
    // </Grid>
  );
}

export default CartItem;
