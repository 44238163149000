import { Container, LinearProgress } from "@mui/material";
import { Fragment, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import {
  Header,
  Home,
  Products,
  ProductDetails,
  Cart,
  Checkout,
  Footer,
} from "./common/components";
import { NAV_CATEGORIES } from "./common/models/static";
import { fetchCategories, fetchShow } from "./features/home.feature";
import { setLoader } from "./features/loader.feature";

function App() {
  const isLoading = useAppSelector(
    (state: RootState) => state.loader.isLoading
  );
  const { show, category, status } = useAppSelector(
    (state: RootState) => state.home
  );
  const productStatus = useAppSelector(
    (state: RootState) => state.products.status
  );
  const cartStatus = useAppSelector((state: RootState) => state.cart.status);
  const checkoutStatus = useAppSelector(
    (state: RootState) => state.checkout.status
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    !category && dispatch(fetchCategories());
    !show &&
      dispatch(
        fetchShow({
          query: "aeiouaeiou"[Math.floor(Math.random() * 10)],
          limit: 10,
          sortDirection: ["asc", "desc"][Math.floor(Math.random() * 2)],
        })
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setLoader(
        status === "loading" ||
          productStatus === "loading" ||
          cartStatus === "loading" ||
          checkoutStatus === "loading"
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, productStatus, cartStatus, checkoutStatus]);

  return (
    <>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        {NAV_CATEGORIES.map((cat) => (
          <Fragment key={cat.path}>
            <Route path={cat.path} element={<Products category={cat.path} />} />
            <Route
              path={`/${cat.path}/:productName/:productId`}
              element={<ProductDetails category={cat.path} />}
            />
          </Fragment>
        ))}
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route
          path="*"
          element={
            <Container>
              <h1 style={{ color: "red" }}>Oops! 404 Page Not Found.</h1>
            </Container>
          }
        />
      </Routes>
      <Footer />
      {isLoading && (
        <LinearProgress
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            zIndex: "9999",
          }}
        />
      )}
    </>
  );
}

export default App;
