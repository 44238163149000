import { Badge, Button, IconButton, SwipeableDrawer } from "@mui/material";
import { Menu, Search, ShoppingCart } from "styled-icons/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { useEffect, useState } from "react";
import { fetchCart } from "../../../../features/cart.feature";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
import { useForm } from "react-hook-form";
import { SearchModel } from "../../../models/Forms";
import { NAV_CATEGORIES } from "../../../models/static";
import logo from "../../../../assets/images/ouda_store.png";

function Header() {
  const navigate = useNavigate();
  const { isLoading } = useAppSelector((state: RootState) => state.loader);
  const { search } = useAppSelector((state: RootState) => state.products);
  const searchForm = useForm<SearchModel>({
    defaultValues: { search: search },
  });
  const { cart } = useAppSelector((state: RootState) => state.cart);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [isSearchMenuActive, setIsSearchMenuActive] = useState(false);
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    dispatch(fetchCart());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchForm.setValue("search", search);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleSearch = searchForm.handleSubmit((data: SearchModel) => {
    if (data.search === search) return;
    let nav = {} as any;
    nav.search = data.search ? `?s=${data.search}` : "";
    (location.pathname === "/" || location.pathname === "/cart") &&
      (nav.pathname = "/products");
    navigate(nav);
  });

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawer(open);
    };

  return (
    <header className={styles.header}>
      <div className="container">
        <nav className={styles.navbar}>
          <IconButton
            className={styles.drawer__btn}
            onClick={toggleDrawer(true)}
            color="primary"
          >
            <Menu width={28} />
          </IconButton>
          <SwipeableDrawer
            // disableBackdropTransition
            anchor="left"
            // swipeAreaWidth={300}
            open={drawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <nav className={styles.drawer}>
              <ul className={styles.drawer__list}>
                <div className={styles.drawer__title}>
                  <h2>Categories</h2>
                </div>
                {NAV_CATEGORIES.map((item) => (
                  <li key={item.path}>
                    <Button
                      component={NavLink}
                      to={item.path}
                      className={styles.drawer__anchor + " anchor__colored"}
                      color="inherit"
                      variant="text"
                      // size="small"
                    >
                      {item.icon}{" "}
                      <span style={{ paddingInlineStart: "12px" }}>
                        {item.name}
                      </span>
                    </Button>
                  </li>
                ))}
              </ul>
            </nav>
          </SwipeableDrawer>

          <Button
            component={NavLink}
            to={"/"}
            className={styles.title}
            size="small"
            color="inherit"
            variant="text"
          >
            {/* <Shop /> */}
            <img src={logo} alt={"OUDA STORE"} className={styles.image} />
            {/* <span>Ouda Store</span> */}
          </Button>
          <div className={styles.grow}>
            <IconButton
              className={styles.search__menu}
              color="primary"
              onClick={() => {
                setIsSearchMenuActive((prev: boolean) => !prev);
              }}
            >
              <Search width={28} />
            </IconButton>
            <form
              className={
                styles.search + (isSearchMenuActive ? " " + styles.active : "")
              }
              onSubmit={handleSearch}
              autoComplete="off"
            >
              <input
                {...searchForm.register("search")}
                type="search"
                placeholder="Search..."
                disabled={isLoading}
              />
              <Button
                aria-label="Search"
                type="submit"
                variant="contained"
                disabled={isLoading}
              >
                <Search width={28} />
              </Button>
            </form>
          </div>
          {(location.pathname === "/cart" ||
            location.pathname === "/checkout") === false && (
            <div className={styles.buttons}>
              <IconButton
                component={Link}
                to={"/cart"}
                aria-label="cart items"
                color="inherit"
              >
                {cart.total_items > 0 ? (
                  <Badge badgeContent={cart.total_items} color="error">
                    <ShoppingCart width={28} />
                  </Badge>
                ) : (
                  <ShoppingCart width={28} />
                )}
              </IconButton>
            </div>
          )}
        </nav>
        {/* <nav>
          <ul>
            {NAV_CATEGORIES.map((item) => (
              <li key={item.path}>
                <NavLink to={item.path}>{item.name}</NavLink>
              </li>
            ))}
          </ul>
        </nav> */}
      </div>
    </header>
  );
}

export default Header;
