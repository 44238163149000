import { Button, Grid } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import {
  clearShipOptionList,
  clearSubdivitionList,
  fetchShipCountries,
  fetchShipOptions,
  fetchSubdivisions,
  generateCheckoutTkn,
  setAddressForm,
  setCheckoutStep,
  setShippingCountry,
  setShippingOption,
  setShippingSubdivision,
} from "../../../../features/checkout.feature";
import useMountedEffect from "../../../hooks/useMountedEffect";
import { AddressInputFieldElements, AddressModel } from "../../../models/Forms";
import MyInputField from "../../inputs/MyInputField";
import MyInputSelect from "../../inputs/MyInputSelect";

function AddressForm() {
  const navigate = useNavigate();
  const { cart } = useAppSelector((state: RootState) => state.cart);

  const isLoading = useAppSelector(
    (state: RootState) => state.loader.isLoading
  );
  const {
    checkoutToken,
    shippingCountryList,
    shippingSubdivisionList,
    shippingOptionList,
    addressFormObj,
    addressFormObj: { shippingCountry, shippingOption, shippingSubdivision },
  } = useAppSelector((state: RootState) => state.checkout);
  const formMethods = useForm<AddressModel>({ defaultValues: addressFormObj });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (typeof cart.id !== "undefined" && cart.line_items.length > 0) {
      dispatch(generateCheckoutTkn(cart.id));
    } else {
      dispatch(setCheckoutStep(0));
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    checkoutToken.id &&
      shippingCountryList.length === 0 &&
      dispatch(fetchShipCountries(checkoutToken.id));

    // return () => {
    //   dispatch(setAddressForm(formMethods.getValues() as AddressModel));
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutToken]);

  useMountedEffect(() => {
    dispatch(setShippingSubdivision(""));
    formMethods.setValue("shippingSubdivision", "");

    formMethods.formState.isSubmitted &&
      formMethods.trigger("shippingSubdivision");

    shippingCountry
      ? dispatch(fetchSubdivisions(shippingCountry))
      : dispatch(clearSubdivitionList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingCountry]);

  useMountedEffect(() => {
    dispatch(setShippingOption(""));
    formMethods.setValue("shippingOption", "");

    formMethods.formState.isSubmitted && formMethods.trigger("shippingOption");

    shippingSubdivision
      ? dispatch(
          fetchShipOptions({
            checkoutTokenId: checkoutToken.id,
            country: shippingCountry,
            stateProvince: shippingSubdivision,
          })
        )
      : dispatch(clearShipOptionList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingSubdivision]);

  const onSubmit = formMethods.handleSubmit((data: AddressModel) => {
    dispatch(setAddressForm(data));
    dispatch(setCheckoutStep(1));
  });

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={3}>
        {AddressInputFieldElements.map(
          (element: typeof AddressInputFieldElements[0], index: number) => {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <MyInputField
                  methods={formMethods}
                  {...element}
                  disabled={isLoading}
                />
              </Grid>
            );
          }
        )}
        <Grid item xs={12} sm={6}>
          <MyInputSelect
            methods={formMethods}
            name="shippingCountry"
            label="Shipping Country"
            disabled={isLoading}
            value={shippingCountry}
            setValue={setShippingCountry}
            data={shippingCountryList}
            validators={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyInputSelect
            methods={formMethods}
            name="shippingSubdivision"
            label="Shipping Subdivision"
            disabled={isLoading}
            value={shippingSubdivision}
            setValue={setShippingSubdivision}
            data={shippingSubdivisionList}
            validators={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyInputSelect
            methods={formMethods}
            name="shippingOption"
            label="Shipping Option"
            disabled={isLoading}
            value={shippingOption}
            setValue={setShippingOption}
            data={shippingOptionList}
            validators={{ required: true }}
          />
        </Grid>
      </Grid>
      <br />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          size={"large"}
          disabled={isLoading}
          onClick={() => {
            dispatch(setAddressForm(formMethods.getValues() as AddressModel));
            navigate("/cart");
          }}
        >
          back to cart
        </Button>
        <Button
          type="submit"
          size="large"
          variant="contained"
          disabled={isLoading}
        >
          next
        </Button>
      </div>
      {/* <p>{JSON.stringify(formMethods.getValues())}</p> */}
    </form>
  );
}

export default AddressForm;
