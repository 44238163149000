import { CategoryCollection } from "@chec/commerce.js/features/categories";
import { ProductCollection } from "@chec/commerce.js/features/products";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commerce } from "../common/lib/commerce";

const initialState = {
  status: "idle",
  error: null as any,
  category: undefined as CategoryCollection | undefined,
  show: undefined as ProductCollection | undefined,
};

export const fetchCategories = createAsyncThunk(
  "home/fetchCategories",
  async () => {
    try {
      return await commerce.categories.list({ sortDirection: "asc" });
    } catch (error) {
      throw await error;
    }
  }
);

type ListShowParams = {
  category_slug?: string;
  category_id?: string;
  query?: string;
  limit?: number;
  active?: boolean;
  price_above?: number;
  price_below?: number;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
};

export const fetchShow = createAsyncThunk(
  "home/fetchShow",
  async ({
    page,
    query,
    category_id,
    category_slug,
    limit,
    sortBy,
    sortDirection,
  }: ListShowParams) => {
    try {
      return await commerce.products.list({
        active: 1,
        page,
        query,
        category_id,
        category_slug,
        limit,
        sortBy,
        sortDirection,
      });
    } catch (error) {
      throw await error;
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        const res = action.payload as CategoryCollection;
        res.data = res.data?.sort(() => Math.random() - 0.5);
        state.category = res;
        // console.log(action.payload);
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchShow.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchShow.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        const res = action.payload as ProductCollection;
        state.show = res;
        // console.log(action.payload);
      })
      .addCase(fetchShow.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// export const {} = homeSlice.actions;
export default homeSlice.reducer;
